<template>
    <div>
        <div class="zx-banner-bar" id="index">
        </div>
        <div class="zx-prod-cont-bar" style="margin-bottom: 0;">
            <div class="zx-prod-cont-title" style="margin-bottom: 0;">
                <h2 style="margin-bottom: 0;">公司简介</h2>
            </div>
        </div>
        <div class="zx-aboutUs-cont-bar">
<!--                <h2 class="zx-aboutUs-cont-title">公司简介</h2>-->
                <div class="zx-aboutUs-first-box">
                    <p>厦门掌讯信息技术有限公司成立于2010年12月，公司秉持“诚信、求真、敬业、创新”核心理念，深耕于金融行业，推动聚合数据并搭建数据安全流通网络，为金融机构及金融监管提供数据要素服务及场景化应用服务。公司核心团队成员主要来自商业银行、通信运营商以及知名互联网企业等一流的专业人才，对大数据、人工智能等技术在金融行业的应用具有丰富经验和很强的创新能力。</p>
                </div>
                <img src="../../assets/product/zx-aboutUs-first-box-img.png" alt="">
        </div>
        <div class="zx-prod-cont-bar">
            <div style="margin-bottom: 0;" class="zx-prod-cont-title">
                <h2 style="margin-bottom: 0;">公司简介</h2>
            </div>
        </div>
        <div class="zx-aboutUs-cont-2-bar">
            <div class="zx-aboutUs-cont-2-list">
                <img src="../../assets/product/aboutUs-img-1.jpeg" alt="">
                <h3>国家级高新技术企业</h3>
            </div>
            <div class="zx-aboutUs-cont-2-list">
                <img src="../../assets/product/aboutUs-img-2.jpeg" alt="">
                <h3>厦门科技小巨人企业</h3>
            </div>

        </div>
        <div class="zx-prod-cont-bar">
            <div class="zx-prod-cont-title">
                <h2>薪资福利</h2>
            </div>
            <h3 class="zx-aboutUs-cont-h2" style="width: 1200px;">掌讯不断在管理机制、薪资福利、员工职业发展、工作环境等方面优化完善，我们拥有行业内有竞争力的薪资体系，人性化的员工关怀，多层次的福利保障，积极进取、和谐融洽的团队氛围，公平的晋升机制，广阔的发展空间，高品质的工作环境，全力打造优秀雇主品牌。</h3>

            <div class="zx-programme-sens-4">
                <div>
                    <img src="../../assets/product/zx-aboutUs-sens-4-icon-1.png" alt="">
                    <p>六险一金</p>
                </div>
                <div>
                    <img src="../../assets/product/zx-aboutUs-sens-4-icon-2.png" alt="">
                    <p>年终奖金</p>
                </div>
                <div>
                    <img src="../../assets/product/zx-aboutUs-sens-4-icon-3.png" alt="">
                    <p>股权激励</p>
                </div>
                <div>
                    <img src="../../assets/product/zx-aboutUs-sens-4-icon-4.png" alt="">
                    <p>双休+假期</p>
                </div>
                <div>
                    <img src="../../assets/product/zx-aboutUs-sens-4-icon-5.png" alt="">
                    <p>贴心礼金</p>
                </div>
            </div>
            <div class="zx-programme-sens-4">
                <div>
                    <img src="../../assets/product/zx-aboutUs-sens-4-icon-6.png" alt="">
                    <p>福利活动</p>
                </div>
                <div>
                    <img src="../../assets/product/zx-aboutUs-sens-4-icon-7.png" alt="">
                    <p>团建经费</p>
                </div>
                <div>
                    <img src="../../assets/product/zx-aboutUs-sens-4-icon-8.png" alt="">
                    <p>下午茶</p>
                </div>
                <div>
                    <img src="../../assets/product/zx-aboutUs-sens-4-icon-9.png" alt="">
                    <p>入职体检</p>
                </div>
                <div>
                    <img src="../../assets/product/zx-aboutUs-sens-4-icon-10.png" alt="">
                    <p>落户厦门</p>
                </div>
            </div>
        </div>
        <div class="zx-prod-cont-bar">
            <div class="zx-prod-cont-title">
                <h2>办公环境</h2>
            </div>
            <div class="zx-programme-sens-5">
                <div class="swiper-container swiper-container2" style="width: 1200px;height: 738px">
                    <div class="swiper-wrapper" style="width: 1200px;">
                        <div class="swiper-slide" >
                            <div style="display: flex;flex-direction: column">
                                <img style="width: 1200px;height: 630px" src="../../assets/product/zx-about-banner-1.png" alt="">
                                <div class="zx-about-us-banner-text">
                                    前台
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 如果需要导航按钮 -->
                    <div class="swiper-button-prev swiper-button-prev1"></div>
                    <div class="swiper-button-next swiper-button-next1"></div>
                </div>
            </div>
        </div>
        <div class="zx-prod-cont-bar">
            <div class="zx-prod-cont-title">
                <h2>联系我们</h2>
            </div>
            <div class="zx-programme-sens-6">
                <div>
                    电话：<a href="tel:0592-5516651">0592-5516651</a>
                </div>
                <div>
                    邮箱：<a href="mailto:sunsing@amicloud.cn">sunsing@amicloud.cn</a>(商务合作）<a href="mailto:hr@amicloud.cn">hr@amicloud.cn</a>人才招聘
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import 'swiper/dist/js/swiper'
    import 'swiper/dist/css/swiper.css'
    import Swiper from "swiper"

    export default {
        name: 'App',
        data(){
            return {
                curNavName: '首页',
                navlist:[
                    {
                        name:'首页',
                        id: 'index'
                    },
                    {
                        name:'产品',
                        id: 'product'
                    },
                    {
                        name:'解决方案',
                        id: 'solution'
                    },
                    {
                        name:'客户案例',
                        id: 'partners'
                    },
                    {
                        name:'关于我们',
                        id: 'aboutUs'
                    }
                ]
            }
        },
        mounted() {
            new Swiper('.swiper-container2', {
                direction: 'horizontal', // 垂直切换选项
                //mousewheel: true, //滚轮
                autoplay: { //自动开始
                    delay: 2500, //时间间隔
                    disableOnInteraction: false, //*手动操作轮播图后不会暂停*
                },
                loop: true, // 循环模式选项
                // pagination: {
                //   el: '.swiper-pagination',
                //   clickable: true, // 分页器可以点击
                // },
                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.swiper-button-next1',
                    prevEl: '.swiper-button-prev1',
                },
            })
        },
    }
</script>

<style lang="scss" scoped>
    .zx-nav-bar {
        height: 83px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99;
        background: #fff;
        box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
        .zx-nav-box {
            width: 1200px;
            height: 83px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .zx-nav {
            display: flex;
            height: 83px;
            align-items: center;

            li {
                padding: 0 22px;
                height: 83px;
                line-height: 83px;
                transition: all .5s;
            }
            a{
                text-decoration: none;
                color: #000000;
                width: 100%;
                height: 100%;
                display: inline-block;
            }
            li:hover {
                background: #305DC4;
                color: #fff;
                cursor: pointer;
                transition: all .5s;
                a{
                    color: #fff;
                    transition: all .5s;
                }
            }

            li.active {
                background: #305DC4;
                color: #fff;
                cursor: pointer;
                transition: all .5s;
                a{
                    color: #fff;
                    transition: all .5s;
                }
            }
        }
    }

    .zx-banner-bar {
        height: 600px;
        background: url("../../assets/product/zx-aboutUs-bg.png") no-repeat;
        background-size:cover ;
    }

    .swiper-container2 {
        height: 100%;
    }
    .zx-product-bar{
        width: 100%;
        height: 750px;
        display: flex;
        justify-content: center;
        background: #ffffff;
    }
    .zx-product-box{
        width: 1200px;
        display: flex;
        align-items: center;
        flex-direction: column;
        .zx-product-title{
            margin-top: 109px;
            margin-bottom: 80px;
            position: relative;
            h2{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 40px;
                line-height: 56px;
                color: #000000;
            }
            h3{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 34px;
                color: rgba(0, 0, 0, 0.25);
            }
            &::after{
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                background: #305DC4;
                bottom: -20px;
                left: 50%;
                transform: translate(-6px, 0);
                z-index: 2;
            }
            &::before{
                position: absolute;
                content: '';
                width: 658px;
                height: 1px;
                bottom: -15px;
                left: 50%;
                transform: translate(-329px, 0);

                background: #D9D9D9;
            }
        }
        .zx-product-sub-nav{
            display: flex;
            width: 390px;
            justify-content: space-between;
            margin-bottom: 45px;
            li{
                width: 120px;
                height: 43px;
                line-height: 43px;
                left: 761px;
                top: 974px;
                background: #FFFFFF;
                border: 1px solid rgba(0, 0, 0, 0.1);
                cursor: inherit;
            }
        }
        .zx-product-list{
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 66px;
            li{
                width: 380px;
                height: 391px;
                background: #FFFFFF;
                box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
                display: flex;
                align-items: center;
                flex-direction: column;

                img{
                    width: 380px;
                    height: 195px;
                    margin-bottom: 7px;
                }
                h3{
                    font-family: 'PingFang HK';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 34px;
                    color: #000000;
                    margin-bottom: 10px;
                }
                p{
                    width: 330px;
                    font-family: 'PingFang HK';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 29px;
                    text-align: center;
                    color: rgba(0, 0, 0, 0.75);
                }
            }
        }
        .more-btn{
            box-sizing: border-box;
            width: 158px;
            height: 41px;
            padding-right: 40px;
            border: 1px solid rgba(0, 0, 0, 0.12);
            filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25));
            align-items: center;
            line-height: 41px;
            position: relative;
            cursor: pointer;
            transition: all .5s;
            &:hover{
                background-color: #305DC4;
                color: #fff;
                transition: all .5s;
            }
            &::after{
                content: '';
                position: absolute;
                width: 40px;
                height: 40px;
                right: 0px;
                top: -1px;
                background: #000000;
            }
            .array{
                position: absolute;
                right: 10px;
                z-index: 2;
                top: 15px;
            }
        }
    }

    .zx-solution-bar{
        width: 100%;
        display: flex;
        justify-content: center;
        background: #fff;
    }
    .zx-solution-box{
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        .zx-solution-title{
            margin-top: 107px;
            margin-bottom: 64px;
            position: relative;
            h2{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 40px;
                line-height: 56px;
                color: #000000;
            }
            h3{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 34px;
                color: rgba(0, 0, 0, 0.25);
            }
            &::after{
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                background: #305DC4;
                bottom: -20px;
                left: 50%;
                transform: translate(-6px, 0);
                z-index: 2;
            }
            &::before{
                position: absolute;
                content: '';
                width: 658px;
                height: 1px;
                bottom: -15px;
                left: 50%;
                transform: translate(-329px, 0);

                background: #D9D9D9;
            }
        }

        .zx-solution-banner{
            width: 100%;
            height: 548px;
            background-image: url("../../assets/solution-banner.png");
            background-repeat: no-repeat;
            background-size: cover;
        }
        .zx-solution-block{
            width: 100%;
            height: 548px;
            background-color: rgba(34, 53, 96, 0.95);
            display: flex;
            align-items: center;
            flex-direction: column;
        }
        .zx-solution-content{
            width: 1214px;
            height: 394px;
            background: #305DC4;
            transform: translate(0, 226px);
            display: flex;
            justify-content: space-between;
            padding:  0 27px;
            box-sizing: border-box;
            img{
                transform: translate(0, -32px);
            }
            >div{
                width: 506px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding-top: 76px;
                margin-right: 50px;
                h3{
                    font-family: 'PingFang HK';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 30px;
                    line-height: 1;
                    color: #FFFFFF;
                    margin-bottom: 54px;
                    position: relative;
                    &::after{
                        content: '';
                        width: 42px;
                        height: 2px;
                        background: #FFFFFF;
                        border-radius: 5px;
                        position: absolute;
                        left: 0;
                        top:45px
                    }
                }
                p{
                    font-family: 'PingFang HK';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 180%;
                    color: #FFFFFF;
                    text-align: left;
                }
                >div{
                    display: flex;
                    width: 100%;
                    justify-content: flex-end;
                    a{
                        width: 68px;
                        height: 36px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid rgba(255, 255, 255, 0.5);
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .zx-solution-bar{
        width: 100%;
        display: flex;
        justify-content: center;
        background: #fff;
        margin-bottom: 190px;
    }
    .zx-partners-box {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        border-bottom: 1px solid #dfdfdf;

        .zx-partners-title {
            margin-bottom: 93px;
            position: relative;

            h2 {
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 40px;
                line-height: 56px;
                color: #000000;
            }

            h3 {
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 34px;
                color: rgba(0, 0, 0, 0.25);
            }

            &::after {
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                background: #305DC4;
                bottom: -20px;
                left: 50%;
                transform: translate(-6px, 0);
                z-index: 2;
            }

            &::before {
                position: absolute;
                content: '';
                width: 658px;
                height: 1px;
                bottom: -15px;
                left: 50%;
                transform: translate(-329px, 0);

                background: #D9D9D9;
            }
        }

        .zx-partners-list{
            display: flex;
            width: 1200px;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 81px;
            botder-bottom: 1px solid rgba(217, 217, 217, 0.9);
        }
    }
    .swiper-container2{
        width: 1200px;
    }
    .zx-footer{
        height: 434px;
        background: #F1F1F1;
        display: flex;
        justify-content: center;
    }

    .zx-footer-box{
        display: flex;padding-right: 100px;box-sizing: border-box;
        width: 800px;
        flex-direction: column;
        >div{
            display: flex;
        }
        .zx-footer-top{
            justify-content: space-between;
            padding-top: 43px;
            margin-bottom: 0px;
        }
        ul{
            display: flex;
            flex-direction: column;
            text-align: left;
            li.title{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 34px;
                color: rgba(0, 0, 0, 0.65);
                margin-bottom: 40px;
            }
            li{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 40px;
                color: rgba(0, 0, 0, 0.5);
            }
        }
    }
    .zx-footer-bottom{
        margin-top: 43px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.5);
        >img{
            margin-bottom: 43px;
        }
        p{
            display: flex;
            align-items: center;
            margin-bottom: 43px;
            img{
                margin-right: 10px;
            }
        }
        a{
            list-style: none;
            text-decoration: none;
            color: rgba(0, 0, 0, 0.5);
        }
    }
    .zx-end{
        width: 1200px;
        margin: 0 auto;
        height: 63px;
        display: flex;
        align-items: center;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.5);
        a{
            font-size: 20px;
            line-height: 28px;
            color: rgba(0, 0, 0, 0.5);
            text-decoration: initial;
            margin-left: 10px;
        }
    }
</style>
<style lang="scss">


    .zx-new-product-bar{
        display: flex;
        justify-content: space-between;
        width: 100%;
        .zx-new-product-list{
            width: 340px;
            height: 440px;
            border: 0.5px solid rgba(0, 0, 0, 0.0);
            h3{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 30px;
                color: #121212;
                margin-bottom: 20px;
            }
            p{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                color: rgba(18, 18, 18, 0.75);
                margin-bottom: 15px;
            }
            a{
                display: inline-block;
                width: 130px;
                height: 40px;
                border-radius: 4px;
                border: 1px solid #2653B8;
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 40px;
                color: #2653B8;
                cursor: pointer;
                &:hover{
                    background: #2653B8;
                    color: #fff;
                    transition: all .5s;
                }
            }
            &:hover{
                background: rgba(252, 252, 252, 0.5);
                border: 0.5px solid rgba(0, 0, 0, 0.1);
                box-shadow: 0px 5px 21px rgba(0, 0, 0, 0.1);
                border-radius: 4px;
                transition: all .5s;
            }
        }
    }


    .zx-new-partners-list-bar{
        display: flex;
        width: 1060px;
        justify-content: space-between;
        flex-wrap: wrap;
        .zx-new-partners-list{
            width: 520px;
            height: 370px;
            background: #FDFDFD;
            border: 0.5px solid rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            p{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 30px;
                color: #2653B8;
                margin-bottom: 15px;
            }
            a{
                display: inline-block;
                width: 130px;
                height: 40px;
                border-radius: 4px;
                border: 1px solid #2653B8;
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 40px;
                color: #2653B8;
                cursor: pointer;
                margin-top: 91px;
                &:hover{
                    background: #2653B8;
                    color: #fff;
                    transition: all .5s;
                }
            }
            &:hover{
                box-shadow: 0px 5px 21px rgba(0, 0, 0, 0.1);
                transition: all .5s;
            }
        }
        .zx-new-partners-list:nth-child(1){
            background:url("../../assets/product/parner-bg-1.jpg") no-repeat 68px 40px;
        }
        .zx-new-partners-list:nth-child(2){
            background:url("../../assets/product/parner-bg-2.jpg") no-repeat 107px 54px ;
        }
        .zx-new-partners-list:nth-child(3){
            background:url("../../assets/product/parner-bg-3.png") no-repeat 63px 18px;
        }
        .zx-new-partners-list:nth-child(4){
            background:url("../../assets/product/parner-bg-4.png") no-repeat 121px 76px;
        }
    }


    .zx-new-aoubt-us-bar{
        h2{
            ont-family: 'PingFang HK';
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 42px;
            color: #000000;
            margin-bottom: 20px;
        }
        p{
            font-family: 'PingFang HK';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 214%;
            color: rgba(18, 18, 18, 0.75);
        }

        margin-bottom: 50px;
    }



</style>
<style lang="scss" scoped>

    .zx-prod-cont-bar{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 140px;
        .zx-prod-cont-title{
            h2{

                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 40px;
                line-height: 56px;
                color: #121212;
                position: relative;
                margin-bottom: 118px;
                &:after{
                    content: '';
                    position: absolute;
                    width: 70px;
                    height: 6px;
                    background: #305DC4;
                    border-radius: 11px;
                    left: 50%;
                    margin-left: -35px;
                    top: 90px;
                }
            }
        }
    }

    .zx-programme-sens-1{
        width: 1200px;
        display: flex;
        justify-content: space-between;
        >div{
            width: 289px;
            height: 337px;
            background: #FFFFFF;
            box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
            img{
                width: 289px;
                height: 176.05px;
            }
            h3{
                height: 54px;
                line-height: 54px;
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                color: #000000;
            }
            p{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 30px;
                /* or 214% */
                text-align: left;
                color: rgba(0, 0, 0, 0.5);
                padding: 0 10px;
            }
        }
    }

    .zx-programme-sens-2{
        >h2{
            width: 1200px;font-family: 'PingFang HK';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 176.02%;
            text-align: center;
            color: rgba(18, 18, 18, 0.75);
        }
    }
    .zx-programme-sens-2-box{
        display: flex;
        width: 1200px;
        margin-top: 26px;
        .zx-programme-sens-2-box-left{
            width: 670px;
            text-align: left;
            margin-top: 90px;
            h3{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 28px;
                line-height: 176.02%;
                color: #121212;
            }
            p{
                margin-top: 67px;
                width: 550px;
                height: 125px;
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 176.02%;
                color: rgba(18, 18, 18, 0.75);
            }
        }
    }

    .zx-programme-advantage-box{
        display: flex;
        justify-content: space-between;
        width: 1200px;
        margin-bottom: 80px;
        background: url("../../assets/product/zx-programme-advantage-box-bg.png") no-repeat center center;
        background-size: 355px;
        ul{
            width: 381px;
            li{
                width: 381px;
                height: 221px;
                box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
                background: #fff;
                margin-bottom: 25px;
                display: flex;
                justify-content: center;
                padding-top: 29px;
                img{
                    width: 73px;
                    height: 100px;
                }
                div{
                    width: 254px;
                    h3{
                        font-family: 'PingFang HK';
                        font-style: normal;
                        font-weight: normal;
                        font-size: 28px;
                        line-height: 176.02%;
                        color: #121212;
                    }
                    p{
                        font-family: 'PingFang HK';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 176.02%;
                        letter-spacing: 0.09em;
                        color: rgba(18, 18, 18, 0.75);
                    }
                }
            }
            &:nth-child(1){
                li{
                    text-align: right;
                }
                img{
                    margin-left: 10px;
                }
            }
            &:nth-child(2){
                li{
                    text-align: left;
                }
                img{
                    margin-right: 10px;
                }
            }
        }
    }
</style>
<style lang="scss" scoped>
    .zx-aboutUs-cont-bar{
        height: 800px;
        width: 1200px;
        position: relative;
        margin: 0 auto;
        .zx-aboutUs-first-box{
            box-sizing: border-box;
            position: absolute;
            width: 666px;
            height: 425px;
            left: 0px;
            top: 266px;
            background: #FFFFFF;
            border: 1px solid rgba(153, 172, 176, 0.25);
            box-shadow: 0px 54px 54px rgba(153, 172, 176, 0.15);
            border-radius: 4px;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            p{
                text-indent: 2em;
                width: 550px;
                height: 320px;
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 214%;
                /* or 43px */

                text-align: center;

                color: rgba(18, 18, 18, 0.75);

            }
        }
        img{
            position: absolute;
            right: 0;
            top: 160px;
            z-index: 0;
        }
        .zx-aboutUs-cont-title{
            font-family: 'PingFang HK';
            font-style: normal;
            font-weight: normal;
            font-size: 40px;
            line-height: 56px;
            color: #121212;
            position: absolute;
            left: 253px;
            top: 140px;
            &:after{
                content: '';
                position: absolute;
                width: 70px;
                height: 6px;
                background: #305DC4;
                border-radius: 11px;
                left: 50%;
                margin-left: -35px;
                top: 90px;
            }
        }
    }

    .zx-aboutUs-cont-2-bar{
        position: relative;
        margin: 118px auto 0;
        width: 1200px;
    }

    .zx-aboutUs-cont-2-bar{
        display: flex;
        justify-content: space-between;
        .zx-aboutUs-cont-2-list{
            height: 440px;
            width: 510px;
            img{
                margin-bottom: 50px;
            }
            h3{
                font-weight: normal;
                font-size: 30px;
                line-height: 105.02%;
                text-align: center;
                color: #000000;

            }
        }
    }

    .zx-aboutUs-cont-h2{
        font-family: 'PingFang HK';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 214%;
        /* or 51px */
        margin-bottom: 90px;
        text-align: center;

        color: rgba(18, 18, 18, 0.75);

    }
    .zx-programme-sens-4{
        display: flex;
        justify-content: space-between;
        width: 1200px;
        margin-bottom: 60px;
        img{
            margin-bottom: 24px;
        }
        p{
            font-family: 'PingFang HK';
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 42px;
            color: #121212;
        }
    }

    .zx-programme-sens-6{
        margin-bottom: 219px;
        >div{
            font-family: 'PingFang HK';
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 42px;
            color: rgba(18, 18, 18, 0.75);
            margin-bottom: 60px;
        }
        a{
            font-family: 'PingFang HK';
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 42px;
            color: rgba(18, 18, 18, 0.75);
            text-decoration: none;
            transition: all .5s;
            &:hover{
                color: #305DC4;
                transition: all .5s;
            }
        }
    }
    .zx-programme-sens-5{
        margin-bottom: 60px;
        position: relative;
        &:after{
            content: '';
            position: absolute;
            width: 160px;
            height: 1px;
            background: #2653B8;
            left: 50%;
            margin-left: -240px;
            bottom: 20px;
        }
        &:before{
            content: '';
            position: absolute;
            width: 160px;
            height: 1px;
            background: #2653B8;
            right: 50%;
            margin-right: -240px;
            bottom: 20px;
        }
    }

    .zx-about-us-banner-text{
        margin-top: 60px;
        height: 48px;
        line-height: 48px;
        font-family: 'PingFang HK';
        font-style: normal;
        font-weight: 400;
        font-size: 34px;
        line-height: 48px;
        /* identical to box height */


        color: #000000;
    }
</style>
